@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  border: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Noto Sans", sans-serif;
  @apply text-sznkki-gray-dark;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track-piece {
  background-color: #f3f3f3;
}
::-webkit-scrollbar-thumb {
  background-color: #989898;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #3a3a3a;
}

.site-px {
  @apply px-4 md:px-8;
}

h1 {
  @apply font-bold uppercase text-3xl sm:text-4xl text-sznkki-gray;
}

h2 {
  @apply font-bold text-2xl text-sznkki-gray-dark;
}

h3 {
  @apply font-bold text-lg text-sznkki-gray-dark;
}

.lead {
  @apply font-bold;
}

.content {
  @apply text-sm leading-7;
}

.content a {
  @apply font-semibold text-sznkki-yellow-dark transition hover:text-sznkki-yellow;
}

.content table {
  @apply min-w-[280px];
}

.content ul, li {
  @apply list-disc list-inside;
}